<template>
  <div class="login">
    <div class="login-title">ログイン</div>
    <!-- <div class="login-register">
      <span>初めにご利用の方は</span>
      <router-link to="/register">新規登録</router-link>
    </div> -->
    <div class="login-docs">
      「<a href = "/terms.html" target="_blank">利用規約</a>」
      「<a href = "https://www.tkhs.co.jp/about/privacy.html" target="_blank">個人情報の取り扱いについて</a>」
      <span>に同意の上ご利用ください。</span>
    </div>
    <el-form ref="loginForm" :model="form" class="app-form" :rules="rules">
      <el-form-item label="ログインID" prop="username">
        <el-input v-model="form.username" autocomplete="off" maxlength="50"
          placeholder="ログインID"
        ></el-input>
      </el-form-item>
      <el-form-item label="パスワード" prop="password" style="margin-bottom: 35px">
        <el-input type="password" v-model="form.password" autocomplete="off" minlength="6"
          placeholder="パスワード"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button  class="studypack-button-primary" type="primary" @click="onSubmit">ログイン</el-button>
      </el-form-item>
      <router-link to="/forgot">パスワードをお忘れですか？</router-link>
    </el-form>
  </div>
</template>

<script>
import request from '@/utils/request';
import tools from '@/utils/tools';
import Cookies from 'js-cookie';
import { Notification } from 'element-ui';

const { checkUserName } = tools;

export default {
  name: 'Login',
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      rules: {
        username: [
          { required: true, message: '必須です。', trigger: 'blur' },
          { max: 50, message: '50文字以内を入力ください。', trigger: 'blur' },
          { validator: checkUserName, trigger: 'blur' },
        ],
        password: [
          { required: true, message: '必須です。', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    onSubmit() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          request('login', {
            userName: this.form.username,
            password: this.form.password,
          }).then((res) => {
            const { redirect } = this.$route.query;
            const { defaultPasswordfalg, expireTime, token } = res;

            Cookies.set('token', token, { expires: expireTime });
            request('getMyProfile').then((res) => {
              Cookies.set('userNameKanji', res.userNameKanji);
              // 如果是默认密码则跳转到修改密码页面
              if (defaultPasswordfalg === '0') {
                Notification({
                title: '注意',
                message: 'パスワードはデフォルトです。パスワードを変更してください。',
                // duration: 0,
                offset: 75,
                type: 'success',
              });
                this.$router.push({
                  path: '/mypage/setpassword',
                }).catch(() => {});
                return;
              }
              this.$router.push({
                path: redirect || '/',
              }).catch(() => {});
            });
          });
          return true;
        }
        console.log('error submit!!');
        return false;
      });
    },
  },
};
</script>

<style lang="less">
.login {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  margin: 40px auto;
  max-width: 600px;

  .login-title {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .login-register {
    text-align: center;
    font-size: 20px;
    margin-bottom: 24px;
  }
  .login-docs {
    text-align: center;
    font-size: 16px;
    margin-bottom: 24px;
  }
}
</style>
